import Vue, { PluginObject } from "vue";
import App from "./App.vue";
import router from "./lib/router";
import store from "./lib/store";
import vuetify from "./plugins/vuetify";
import snackbar from "./plugins/snackbar";
import BaResultComponents from "@memberpoint/ba-result-components";
import Authentication from "@/lib/Authentication";
import "@/assets/style/app.scss";
import LocalStorage from "@/lib/LocalStorage";
import SavedPrograms from "@/services/SavedPrograms";
import MpComponents from "@memberpoint/mp-components";

declare let cordova: Cordova;

import "@fontsource/poppins";

Vue.config.productionTip = false;

Vue.use(
  (BaResultComponents as unknown) as PluginObject<{ [key: string]: unknown }>
);
Vue.use(MpComponents);

Vue.use(snackbar, { store });

/*
 * Preload store from local storage
 */
store.subscribe((mutation, state) => {
  if (mutation.type === "app/setSettings") {
    LocalStorage.set("settings", state.app.settings);
  }
});
store.commit("loadStore");
SavedPrograms.init();

/*
 * Set up authentication events
 */
Authentication.onStateChange((evt: string, data: unknown) => {
  if (evt === "login") {
    store.commit("auth/login", data);
  } else if (evt === "logout") {
    store.commit("auth/logout");

    if (typeof data === "string" && data === "refresh") {
      router.push({
        name: "auth-login",
        query: {
          redirect: router.currentRoute.fullPath,
        },
      });
    }
  }
});

function loadApp() {
  Authentication.init(true).then(() => {
    new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount("#app");
  });
}

if (typeof cordova !== "undefined") {
  document.addEventListener(
    "deviceready",
    () => {
      loadApp();
    },
    false
  );
} else {
  loadApp();
}
