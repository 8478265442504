import Vue from "vue";
import LocalStorage from "@/lib/LocalStorage";
import Program from "@/Classes/Program";

export default new Vue({
  data() {
    return {
      programs: [] as Program[],
    };
  },
  methods: {
    /**
     * Triggered when the store is updated
     */
    changed() {
      this.save();
      this.$emit("change");
    },

    /**
     * Export saved matched
     */
    save(): string {
      const packed = JSON.stringify(this.programs);
      LocalStorage.set("programs", packed);
      return packed;
    },

    /**
     * Get the collection of programs
     */
    getPrograms(): Program[] {
      return this.programs;
    },

    /**
     * Load saved programs from local storage
     */
    init(): void {
      if (LocalStorage.has("programs")) {
        const programs = JSON.parse(
          LocalStorage.get("programs") as string
        ) as Array<unknown>;
        this.programs = [];
        programs.forEach((program) => {
          this.programs.push(new Program(program));
        });
        this.changed();
      }
    },

    /**
     * If there are saved programs
     */
    hasPrograms(): boolean {
      return this.programs.length > 0;
    },

    /**
     * Check to see if a specific program is saved
     * @param matchId
     */
    hasProgram(programId: string): boolean {
      return (
        this.programs.findIndex((program) => program.id === programId) !== -1
      );
    },
  },
});
