import Vue from "vue";
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from "vue-router";
import store from "../store";
import { Plugins } from "@capacitor/core";

const { App } = Plugins;

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/auth",
    name: "auth",
    redirect: { name: "auth-login" },
    meta: {
      public: true,
    },
    component: () => import("@/templates/auth/index.vue"),
    children: [
      {
        path: "login",
        name: "auth-login",
        component: () => import("@/controllers/auth/login/index.vue"),
      },
      {
        path: "logout",
        name: "auth-logout",
        component: () => import("@/controllers/auth/logout/index.vue"),
      },
    ],
  },
  {
    path: "",
    redirect: "/app",
  },
  {
    path: "/app",
    name: "app",
    redirect: { name: "app-landing" },
    meta: {
      public: false,
    },
    component: () => import("@/templates/app/index.vue"),
    children: [
      {
        path: "home",
        name: "app-landing",
        component: () => import("@/controllers/app/home/index.vue"),
      },
      {
        path: "programs",
        name: "app-programs",
        props: true,
        component: () => import("@/controllers/app/programs/index.vue"),
      },
      {
        path: "programs/attendance/:id",
        name: "app-programs-attendance",
        props: true,
        component: () =>
          import("@/controllers/app/programs/attendance/index.vue"),
      },
      {
        path:
          "programs/attendance/register/:registrationType/:programId/:sessionId",
        name: "app-programs-attendance-register",
        props: true,
        component: () =>
          import("@/controllers/app/programs/attendance/register/index.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

const loginGuard = (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
  if (to.matched.some((record) => record.meta.public === false)) {
    if (!store.getters["auth/isLoggedIn"]) {
      next({
        name: "auth-login",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
};

router.beforeEach(loginGuard);

router.beforeEach((to, from, next) => {
  const pageTitle = to.meta.title || "Reclink"; // Set a default title or customize it per route
  document.title = pageTitle;
  next();
});

App.addListener("appUrlOpen", (data) => {
  const url = new URL(data.url);

  const path = "/" + url.pathname.replace(/^\/+/, "") + url.search;

  router.push(path);
});

/*
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.public)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters.isLoggedIn) {
      next({ name: "auth-login" });
    } else {
      next(); // go to wherever I'm going
    }
  } else {
    next(); // does not require auth, make sure to always call next()!
  }
});
 */

export default router;
