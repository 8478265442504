/**
 * Authentication storage for Vue
 */
export type ComponentState = {
  navShown: boolean;
  guestTypes: Record<string, string>;
};

export default {
  namespaced: true,
  state: {
    navShown: false,
    markerOptions: [],
  },
  getters: {
    isNavVisible(state: ComponentState) {
      return state.navShown;
    },
    guestTypes(state: ComponentState) {
      return state.guestTypes;
    },
  },
  actions: {},
  mutations: {
    toggleNav: (state: ComponentState, newState: boolean | null = null) => {
      if (newState === null) {
        state.navShown = !state.navShown;
      } else {
        state.navShown = newState;
      }
    },
    setGuestTypes: (
      state: ComponentState,
      guestTypes: Record<string, string> = {}
    ) => {
      state.guestTypes = guestTypes;
    },
  },
};
