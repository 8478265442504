/**
 * Authentication storage for Vue
 */

export type AppState = {
  settings: AppSettings;
};
export type AppSettings = {
  isBetaPlatform: boolean;
};
export default {
  namespaced: true,
  state: {
    settings: {
      isBetaPlatform: false,
    },
  } as AppState,
  getters: {
    settings(state: AppState): AppSettings {
      return state.settings;
    },
  },
  actions: {},
  mutations: {
    setSettings(state: AppState, settings: AppSettings) {
      state.settings.isBetaPlatform = settings.isBetaPlatform;
    },
  },
};
