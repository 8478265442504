import Attendance from "./Attendance";
import Guest from "./Guest";

export default class Session {
  id: string;
  attributes: SessionAttributes;

  constructor(session: any) {
    this.id = session.id;
    this.attributes = new SessionAttributes(session.attributes);
  }

  public get attendance(): Attendance[] {
    return this.attributes.attendance;
  }
}

class SessionAttributes {
  title: string | null;
  notes: string | null;
  programName: string | null;
  isPublished: boolean | null;
  instanceCreatedDate: string | null;
  startDateTimeUtc: string | null;
  endDateTimeUtc: string | null;
  hasLocation: boolean | null;
  enrolmentCount: number | null;
  attendanceCount: number | null;
  attendance: Attendance[];
  guests: Guest[];

  constructor(attributes: any) {
    this.title = attributes.title ?? "";
    this.notes = attributes.notes ?? "";
    this.programName = attributes.programName ?? "";
    this.isPublished = attributes.isPublished ?? false;
    this.instanceCreatedDate = attributes.instanceCreatedDate.date ?? null;
    this.startDateTimeUtc = attributes.startDateTimeUtc ?? null;
    this.endDateTimeUtc = attributes.endDateTimeUtc ?? null;
    this.hasLocation = attributes.hasLocation ?? false;
    this.enrolmentCount = attributes.enrolmentCount ?? 0;
    this.attendanceCount = attributes.attendanceCount ?? 0;
    this.attendance =
      attributes.attendance.map((attendee: any) => new Attendance(attendee)) ??
      [];
    this.guests = attributes.guests.map((guest: any) => new Guest(guest)) ?? [];
  }
}
