export default class Guest {
  id: string;
  attributes: GuestAttributes;

  constructor(guest: any) {
    this.id = guest.id;
    this.attributes = new GuestAttributes(guest.attributes);
  }
}

class GuestAttributes {
  sessionName: string | null;
  sessionId: string | null;
  label: string | null;
  age: number | null;
  postcode: string | null;
  type: string | null;
  quantity: number;
  dateCreated: boolean | null;
  addedBy: boolean | null;

  constructor(attributes: any) {
    this.sessionName = attributes.sessionName ?? null;
    this.sessionId = attributes.sessionId ?? null;
    this.label = attributes.label ?? null;
    this.age = attributes.age ?? null;
    this.postcode = attributes.postcode ?? null;
    this.type = attributes.type ?? null;
    this.quantity = attributes.quantity ?? 1;
    this.dateCreated = attributes.dateCreated ?? null;
    this.addedBy = attributes.addedBy ?? null;
  }
}
