import Session from "./Session";

export default class Program {
  id: string;
  attributes: ProgramAttributes;

  constructor(program: any) {
    this.id = program.id;
    this.attributes = new ProgramAttributes(program.attributes);
  }
}
export class ProgramAttributes {
  id: string;
  title: string | null;
  subtitle: string | null;
  description: string | null;
  handle: string | null;
  prc: string | null;
  bannerImageUrl: string | null;
  formattedPrc: string | null;
  status: string | null;
  isPublished: boolean | null;
  ownerHandle: string | null;
  ownerLabel: string | null;
  instanceCreatedDate: string | null;
  startDateTimeUtc: string | null;
  endDateTimeUtc: string | null;
  enrolmentCount: number | null;
  typeId: string | null;
  typeLabel: string | null;
  programGroupId: string | null;
  programGroupLabel: string | null;
  sessionCount: number | null;
  maxEnrolments: number | null;
  createdByLabel: string | null;
  hasLocation: boolean | null;
  sessions: Session[];

  constructor(attributes: ProgramAttributes) {
    this.id = attributes.id;
    this.title = attributes.title ?? "";
    this.subtitle = attributes.subtitle ?? "";
    this.description = attributes.description ?? "";
    this.handle = attributes.handle ?? "";
    this.prc = attributes.prc ?? "";
    this.bannerImageUrl = attributes.bannerImageUrl ?? null;
    this.formattedPrc = attributes.formattedPrc ?? "";
    this.status = attributes.status ?? "";
    this.isPublished = attributes.isPublished ?? false;
    this.ownerHandle = attributes.ownerHandle ?? "";
    this.ownerLabel = attributes.ownerLabel ?? "";
    this.instanceCreatedDate = attributes.instanceCreatedDate ?? "";
    this.startDateTimeUtc = attributes.startDateTimeUtc ?? null;
    this.endDateTimeUtc = attributes.endDateTimeUtc ?? null;
    this.enrolmentCount = attributes.enrolmentCount ?? 0;
    this.typeId = attributes.typeId ?? null;
    this.typeLabel = attributes.typeLabel ?? null;
    this.programGroupId = attributes.programGroupId ?? null;
    this.programGroupLabel = attributes.programGroupLabel ?? null;
    this.sessionCount = attributes.sessionCount ?? 0;
    this.maxEnrolments = attributes.maxEnrolments ?? null;
    this.createdByLabel = attributes.createdByLabel ?? null;
    this.hasLocation = attributes.hasLocation ?? false;
    this.sessions = attributes.sessions
      ? attributes.sessions.map((session) => new Session(session))
      : [];
  }
}
