import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdiSvg",
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#322C82",
        navbar: "#322C82",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        "success-light": "#86C232",
        warning: "#FFC107",
        cloud: "#F5F7FB",
        navy: "#324261",
        red: "#CF0303",
        green: "#00870D",
      },
    },
  },
});
