export default class Attendance {
  id: string;
  attributes: AttendanceAttributes;

  constructor(attendance: any) {
    this.id = attendance.id;
    this.attributes = new AttendanceAttributes(attendance.attributes);
  }
  public get isCheckedIn(): boolean {
    return this.attributes.isCheckedIn ?? false;
  }
}

class AttendanceAttributes {
  enrolmentFirstName: string | null;
  enrolmentLastName: string | null;
  enrolmentPreferredName: string | null;
  enrolmentPhoneNumber: string | null;
  enrolmentEmailAddress: string | null;
  enrolmentNin: string | null;
  enrolmentApproved: boolean | null;
  enrolmentPhotoOptOut: boolean | null;
  enrolmentUnder18: boolean | null;
  enrolmentId: string | null;
  hasAttendance: boolean | null;
  attendanceIsGuest: boolean | null;
  attendanceGuestAge: number | null;
  attendanceGuestPostcode: string | null;
  attendanceId: number | null;
  isCheckedIn: boolean | null;
  dateCheckedIn: string | null;
  dateRegistered: string | null;

  constructor(attributes: any) {
    this.enrolmentFirstName = attributes.enrolmentFirstName ?? null;
    this.enrolmentLastName = attributes.enrolmentLastName ?? null;
    this.enrolmentPreferredName = attributes.enrolmentPreferredName ?? null;
    this.enrolmentPhoneNumber = attributes.enrolmentPhoneNumber ?? null;
    this.enrolmentEmailAddress = attributes.enrolmentEmailAddress ?? null;
    this.enrolmentNin = attributes.enrolmentNin ?? null;
    this.enrolmentApproved = attributes.enrolmentApproved ?? null;
    this.enrolmentPhotoOptOut = attributes.enrolmentPhotoOptOut ?? null;
    this.enrolmentUnder18 = attributes.enrolmentUnder18 ?? null;
    this.enrolmentId = attributes.enrolmentId ?? null;
    this.hasAttendance = attributes.hasAttendance ?? null;
    this.attendanceIsGuest = attributes.attendanceIsGuest ?? null;
    this.attendanceGuestAge = attributes.attendanceGuestAge ?? null;
    this.attendanceGuestPostcode = attributes.attendanceGuestPostcode ?? null;
    this.attendanceId = attributes.attendanceId ?? null;
    this.isCheckedIn = attributes.isCheckedIn ?? null;
    this.dateCheckedIn = attributes.dateCheckedIn
      ? attributes.dateCheckedIn.date
      : null;
    this.dateRegistered = attributes.dateRegistered
      ? attributes.dateRegistered.date
      : null;
  }
}
